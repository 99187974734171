import React from 'react';
import { injectTestId } from '@cvent/nucleus-test-automation';

/**
 * Renders LockIllustration svg
 *
 * @param {object} props - testId to identify illustration in tests
 * @returns Rendered svg illustration
 */
function LockIllustration({ testId }: { testId: string }): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...injectTestId(testId)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4375 20.8542H34.3437V16.8438C34.3437 11.4072 29.9365 7 24.5 7C19.0634 7 14.6562 11.4072 14.6562 16.8438V20.8542H13.5625C11.9517 20.8542 10.6458 22.16 10.6458 23.7708V39.0833C10.6458 40.6942 11.9517 42 13.5625 42H35.4375C37.0483 42 38.3542 40.6942 38.3542 39.0833V23.7708C38.3542 22.16 37.0483 20.8542 35.4375 20.8542ZM24.5 33.9792C22.8892 33.9792 21.5833 32.6733 21.5833 31.0625C21.5833 29.4517 22.8892 28.1458 24.5 28.1458C26.1108 28.1458 27.4167 29.4517 27.4167 31.0625C27.4167 32.6733 26.1108 33.9792 24.5 33.9792ZM29.9687 20.8542C30.3715 20.8542 30.6979 20.5277 30.6979 20.125V16.8438C30.6979 13.4207 27.923 10.6458 24.5 10.6458C21.077 10.6458 18.3021 13.4207 18.3021 16.8438V20.125C18.3021 20.5277 18.6285 20.8542 19.0312 20.8542H29.9687Z"
        fill="url(#paint0_linear_308_432)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_308_432"
          x1="10.6458"
          y1="24"
          x2="38.3542"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LockIllustration;

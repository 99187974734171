import React, { useEffect } from 'react';
import { NavMetadata } from '@cvent/planner-navigation/types';
import { useRouter } from 'next/router';
import { GetServerSidePropsResult, GetServerSidePropsContext, Redirect } from 'next';
import { AuthProps, getAuthPropsOrRedirect } from '@cvent/nextjs/auth';
import Overview from '../components/overview/Overview';

const navMetadata: NavMetadata = {
  url: '/',
  staticRouteId: '/',
  topParentId: ''
};

export const getServerSideProps = async (ctx: GetServerSidePropsContext): Promise<GetServerSidePropsResult<object>> => {
  const authProps: { redirect: Redirect } | { props: AuthProps } = await getAuthPropsOrRedirect(ctx, {});
  if ('props' in authProps && 'auth' in authProps.props) {
    // If the returned object has 'auth' in it, it means that authentication has passed and we can move
    // to the /applications page, which is a protected page.
    return { redirect: { permanent: false, destination: '/applications' } };
  }
  // If the returned object doesn't have 'auth' in it, it means that authentication has failed, so stay on the same page.
  return { props: {} };
};

function IndexPage(): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    // currently if someone accepts an invite, the accept link will include a hash route, i.e. /#/accept/...
    // this app doesn't work with hash routing, so we need to strip the hash and send them to the "normal" /accept endpoint.
    if (window.location?.hash.startsWith('#/accept')) {
      // push the router to be the same URL, minus the hash. The hash property of the window.location doesn't include the
      // leading '/', so we just need to remove the hash and preserve the query params by sending everything after the hash
      router.push(window.location.hash.substring(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Overview />;
}

IndexPage.navMetadata = navMetadata;

export default IndexPage;

import React from 'react';
import { injectTestId } from '@cvent/nucleus-test-automation';

/**
 * Renders TelecommunicatorIllustration svg
 *
 * @param {object} props - testId to identify illustration in tests
 * @returns Rendered svg illustration
 */
function TelecommunicatorIllustration({ testId }: { testId: string }): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...injectTestId(testId)}
    >
      <path
        d="M34.9544 26.4979C34.7961 26.4974 34.6556 26.5994 34.6073 26.7502C34.0013 28.4942 32.9253 30.037 31.4982 31.2083C31.4051 31.2865 31.3573 31.406 31.3708 31.5267C31.3843 31.6475 31.4572 31.7535 31.5652 31.8092C32.0879 32.0706 32.6669 32.1993 33.2511 32.184C35.2413 32.0999 37.1153 31.2235 38.4559 29.75C38.6136 29.5637 38.6678 29.3109 38.6 29.0764C38.5323 28.8419 38.3518 28.6568 38.119 28.5833C37.169 28.2371 36.3775 27.557 35.8921 26.67C35.8256 26.5613 35.7074 26.495 35.58 26.495L34.9544 26.4979Z"
        fill="url(#paint0_linear_313_388)"
      />
      <path
        d="M32.7201 33.2121L32.3424 33.1042C32.0522 33.0212 31.7409 33.1256 31.5593 33.3667C30.721 34.3597 29.6561 35.1365 28.4545 35.6315C28.3172 35.69 28.2302 35.8271 28.2357 35.9762C28.2412 36.1253 28.3381 36.2556 28.4793 36.3038L33.1314 37.924C33.2524 37.966 33.3866 37.9415 33.4849 37.8593C33.5831 37.7772 33.6311 37.6495 33.6112 37.523L32.9797 33.5067C32.958 33.3663 32.8567 33.2514 32.7201 33.2121Z"
        fill="url(#paint1_linear_313_388)"
      />
      <path
        d="M17.8729 37.9167L22.4754 36.2834C22.6151 36.2351 22.7111 36.1063 22.7176 35.9586C22.724 35.8109 22.6395 35.6743 22.5045 35.614C21.3198 35.12 20.2699 34.3506 19.442 33.3696C19.2605 33.1285 18.9491 33.0241 18.6589 33.1071L18.2812 33.2151C18.1455 33.2539 18.0444 33.3675 18.0216 33.5067L17.3916 37.5128C17.3712 37.6399 17.419 37.7684 17.5177 37.8512C17.6163 37.934 17.7512 37.9589 17.8729 37.9167Z"
        fill="url(#paint2_linear_313_388)"
      />
      <path
        d="M38.6863 34.9183L35.0404 33.8756C34.9208 33.8413 34.7918 33.8703 34.6985 33.9527C34.6051 34.0351 34.5603 34.1595 34.5796 34.2825L35.3365 39.095C35.3759 39.3482 35.2796 39.6035 35.0827 39.7675C34.8858 39.9315 34.6173 39.9802 34.3754 39.8956L27.565 37.5215C27.3417 37.4434 27.0945 37.4785 26.9017 37.6156C26.709 37.7527 26.5947 37.9747 26.5952 38.2113V41.0958C26.5952 41.4985 26.9217 41.825 27.3244 41.825H42.2563C42.667 41.825 43 41.492 43 41.0813C42.9859 38.329 41.2673 35.8738 38.6863 34.9183Z"
        fill="url(#paint3_linear_313_388)"
      />
      <path
        d="M15.9202 39.7746C15.7224 39.6107 15.6254 39.3547 15.665 39.1009L16.4204 34.2884C16.4403 34.1655 16.3959 34.041 16.3027 33.9585C16.2096 33.876 16.0806 33.8469 15.961 33.8815L12.2598 34.9417C9.70494 35.908 8.01072 38.3498 8 41.0813C8 41.2785 8.07836 41.4677 8.21784 41.6072C8.35732 41.7467 8.5465 41.825 8.74375 41.825H23.6771C24.0798 41.825 24.4062 41.4986 24.4062 41.0959V38.1792C24.4061 37.9425 24.2911 37.7205 24.0977 37.584C23.9043 37.4474 23.6567 37.4133 23.4335 37.4923L16.629 39.9015C16.3868 39.9869 16.1176 39.9387 15.9202 39.7746Z"
        fill="url(#paint4_linear_313_388)"
      />
      <path
        d="M20.2223 20.2402C19.7995 20.6508 19.7775 21.3225 20.1725 21.7599C20.5675 22.1973 21.2379 22.2437 21.6894 21.8648C21.9716 21.7029 22.3186 21.7029 22.6008 21.8648C23.0523 22.2437 23.7227 22.1973 24.1177 21.7599C24.5127 21.3225 24.4907 20.6508 24.0679 20.2402C22.947 19.3317 21.3432 19.3317 20.2223 20.2402Z"
        fill="url(#paint5_linear_313_388)"
      />
      <path
        d="M28.3466 21.8648C28.6289 21.7029 28.9758 21.7029 29.2581 21.8648C29.7096 22.2437 30.3799 22.1973 30.7749 21.7599C31.17 21.3225 31.148 20.6508 30.7252 20.2402C29.6042 19.3317 28.0004 19.3317 26.8795 20.2402C26.4567 20.6508 26.4347 21.3225 26.8297 21.7599C27.2248 22.1973 27.8951 22.2437 28.3466 21.8648Z"
        fill="url(#paint6_linear_313_388)"
      />
      <path
        d="M20.1275 30.2648C21.6052 31.6213 23.476 32.4726 25.4694 32.6959C26.6604 32.6189 27.8163 32.2611 28.8425 31.6517C29.3725 31.3614 29.5667 30.6964 29.2764 30.1664C28.986 29.6364 28.321 29.4422 27.7911 29.7325C27.0858 30.1634 26.292 30.4287 25.4694 30.5084C23.9644 30.5084 19.884 28.3355 19.3342 25.0571C19.196 24.2185 18.6492 23.5038 17.8759 23.1511C17.3462 22.7952 17.0366 22.192 17.0563 21.5542C17.0235 21.3301 17.0755 21.1018 17.2021 20.914C17.6317 20.6993 17.8717 20.2301 17.7942 19.7561C17.6994 19.1859 17.6367 18.6448 17.5944 18.1228C17.5871 18.0216 17.622 17.9219 17.6908 17.8473C17.7596 17.7728 17.8561 17.73 17.9575 17.729C20.6113 17.877 23.2093 16.9279 25.1427 15.104C25.2486 15.0008 25.4175 15.0008 25.5234 15.104C27.5309 16.979 30.228 17.9343 32.9681 17.7407C33.0701 17.7409 33.1674 17.7838 33.2365 17.8588C33.3066 17.9332 33.3416 18.034 33.3327 18.1359C33.2904 18.6521 33.2292 19.1859 33.1344 19.7503C33.1102 19.8957 33.1156 20.0445 33.1504 20.1878V23.6878C31.9752 25.2217 30.1551 26.1239 28.2227 26.1305H26.0352C25.4312 26.1305 24.9415 26.6202 24.9415 27.2242C24.9415 27.8283 25.4312 28.318 26.0352 28.318H28.2227C30.6576 28.3075 32.9689 27.2439 34.5606 25.4013H36.4375C38.2497 25.4013 39.7188 23.9322 39.7188 22.12C39.7188 20.3079 38.2497 18.8388 36.4375 18.8388H35.4677C35.8775 15.1303 35.2373 12.2311 33.5354 10.2215C31.8336 8.21192 29.1079 7.17505 25.4694 7.17505C21.8309 7.17505 19.1213 8.20026 17.4063 10.2244C15.6067 12.3492 14.9811 15.4584 15.5454 19.4907C15.0669 20.0846 14.8258 20.835 14.8688 21.5965C14.8907 22.3678 15.109 23.1207 15.5031 23.784C15.4005 24.211 15.2764 24.6325 15.1313 25.0469C14.7367 26.6222 13.6373 27.9272 12.1519 28.5834C11.9191 28.6569 11.7386 28.8419 11.6708 29.0764C11.6031 29.311 11.6572 29.5638 11.815 29.75C13.1545 31.2257 15.0287 32.1042 17.0198 32.1898C18.3542 32.2529 19.5896 31.4876 20.1275 30.2648Z"
        fill="url(#paint7_linear_313_388)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_313_388"
          x1="31.3685"
          y1="29.2588"
          x2="38.6287"
          y2="29.2588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_313_388"
          x1="28.2355"
          y1="35.4406"
          x2="33.6156"
          y2="35.4406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_313_388"
          x1="17.387"
          y1="35.4385"
          x2="22.7179"
          y2="35.4385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_313_388"
          x1="26.5952"
          y1="37.7295"
          x2="43"
          y2="37.7295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_313_388"
          x1="8"
          y1="37.7324"
          x2="24.4062"
          y2="37.7324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_313_388"
          x1="19.8901"
          y1="20.8034"
          x2="24.4001"
          y2="20.8034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_313_388"
          x1="26.5474"
          y1="20.8034"
          x2="31.0573"
          y2="20.8034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_313_388"
          x1="11.6422"
          y1="19.5709"
          x2="39.7188"
          y2="19.5709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TelecommunicatorIllustration;

import React from 'react';
import { injectTestId } from '@cvent/nucleus-test-automation';

/**
 * Renders PresentationProjectionScreenIllustration svg
 *
 * @param {object} props - testId to identify illustration in tests
 * @returns Rendered svg illustration
 */
function PresentationProjectionScreenIllustration({ testId }: { testId: string }): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...injectTestId(testId)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8125 8.36459C42.0206 8.36459 43 9.34397 43 10.5521C42.9995 11.6393 42.2006 12.5612 41.1246 12.7163C40.9454 12.743 40.8127 12.8967 40.8125 13.0779V34.2865C40.8119 34.4599 40.9341 34.6096 41.1041 34.6438C41.8384 34.7929 42.3402 35.4741 42.2649 36.2195C42.1896 36.9649 41.5617 37.532 40.8125 37.5313H26.9583C26.8615 37.5309 26.7685 37.5692 26.7001 37.6376C26.6316 37.7061 26.5933 37.799 26.5937 37.8958V38.3538C26.5959 38.4704 26.6539 38.5789 26.7498 38.6454C27.5342 39.1915 27.8733 40.1837 27.5871 41.0957C27.301 42.0077 26.4558 42.6282 25.5 42.6282C24.5442 42.6282 23.699 42.0077 23.4128 41.0957C23.1267 40.1837 23.4657 39.1915 24.2502 38.6454C24.346 38.5789 24.4041 38.4704 24.4062 38.3538V37.8958C24.4066 37.799 24.3683 37.7061 24.2999 37.6376C24.2314 37.5692 24.1385 37.5309 24.0417 37.5313H10.1875C9.43826 37.532 8.81036 36.9649 8.73507 36.2195C8.65977 35.4741 9.16158 34.7929 9.89582 34.6438C10.0653 34.6086 10.187 34.4595 10.1875 34.2865V13.0779C10.1879 12.8965 10.0549 12.7424 9.8754 12.7163C8.7427 12.5495 7.93019 11.5376 8.01209 10.3956C8.09399 9.25364 9.04258 8.368 10.1875 8.36459H40.8125ZM37.5312 34.6146C37.7326 34.6146 37.8958 34.4514 37.8958 34.25V13.1042C37.8962 13.0074 37.8579 12.9144 37.7895 12.8459C37.721 12.7775 37.628 12.7392 37.5312 12.7396H13.4687C13.3719 12.7392 13.279 12.7775 13.2105 12.8459C13.142 12.9144 13.1038 13.0074 13.1041 13.1042V34.25C13.1041 34.4514 13.2674 34.6146 13.4687 34.6146H37.5312Z"
        fill="url(#paint0_linear_308_371)"
      />
      <path
        d="M15.2916 32.0625H35.7083C36.3124 32.0625 36.802 31.5728 36.802 30.9688C36.802 30.3647 36.3124 29.875 35.7083 29.875H35.3437C35.1424 29.875 34.9791 29.7118 34.9791 29.5104V17.1146C34.9791 16.7119 34.6527 16.3854 34.25 16.3854H31.3333C30.9306 16.3854 30.6041 16.7119 30.6041 17.1146V29.5104C30.6041 29.7118 30.4409 29.875 30.2395 29.875H28.052C27.8507 29.875 27.6875 29.7118 27.6875 29.5104V21.4896C27.6875 21.0869 27.361 20.7604 26.9583 20.7604H24.0416C23.6389 20.7604 23.3125 21.0869 23.3125 21.4896V29.5104C23.3125 29.7118 23.1492 29.875 22.9479 29.875H20.7604C20.559 29.875 20.3958 29.7118 20.3958 29.5104V25.8646C20.3958 25.4619 20.0693 25.1354 19.6666 25.1354H16.75C16.3473 25.1354 16.0208 25.4619 16.0208 25.8646V29.5104C16.0208 29.7118 15.8576 29.875 15.6562 29.875H15.2916C14.6876 29.875 14.1979 30.3647 14.1979 30.9688C14.1979 31.5728 14.6876 32.0625 15.2916 32.0625Z"
        fill="url(#paint1_linear_308_371)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_308_371"
          x1="8.00641"
          y1="25.0069"
          x2="43"
          y2="25.0069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_308_371"
          x1="14.1979"
          y1="24"
          x2="36.802"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PresentationProjectionScreenIllustration;

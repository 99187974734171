import React from 'react';
import { injectTestId } from '@cvent/nucleus-test-automation';

/**
 * Renders StarRecognitionIllustration svg
 *
 * @param {object} props - testId to identify illustration in tests
 * @returns Rendered svg illustration
 */
function StarRecognitionIllustration({ testId }: { testId: string }): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...injectTestId(testId)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1002 23.0427C41.953 22.2456 42.2277 21.0061 41.7915 19.9233C41.3484 18.8589 40.2957 18.1771 39.1431 18.2083H32.1533C31.5927 18.207 31.0825 17.8844 30.8408 17.3785L27.125 9.61582V9.60415C26.6165 8.62193 25.6016 8.0064 24.4956 8.00947C23.3895 8.01254 22.378 8.6337 21.875 9.61874L18.1548 17.38C17.9128 17.8853 17.4026 18.2073 16.8423 18.2083H9.85251C8.70214 18.1766 7.65105 18.8566 7.20855 19.9189C6.77254 20.999 7.04413 22.2359 7.89251 23.0339L14.1269 29.4244C14.5419 29.8497 14.6572 30.4843 14.4185 31.0285L10.9813 38.9385C10.4747 40.075 10.7653 41.4088 11.6988 42.2314C12.2361 42.7234 12.9375 42.9974 13.666 43C14.1777 42.9975 14.68 42.8628 15.1244 42.6091L23.7781 37.7354C24.2226 37.4849 24.7657 37.4849 25.2102 37.7354L33.864 42.6091C34.9675 43.2464 36.3569 43.0934 37.2954 42.2314C38.228 41.4083 38.5185 40.0751 38.0129 38.9385L34.5727 31.0242C34.334 30.4799 34.4494 29.8453 34.8644 29.42L41.1002 23.0427ZM24.5 33.5208C22.4865 33.5208 20.8542 31.8885 20.8542 29.875C20.8542 27.8615 22.4865 26.2292 24.5 26.2292C26.5135 26.2292 28.1458 27.8615 28.1458 29.875C28.1458 31.8885 26.5135 33.5208 24.5 33.5208ZM20.8542 23.6771H28.1458C28.7499 23.6771 29.2396 23.1874 29.2396 22.5833C29.2396 21.9793 28.7499 21.4896 28.1458 21.4896H20.8542C20.2501 21.4896 19.7604 21.9793 19.7604 22.5833C19.7604 23.1874 20.2501 23.6771 20.8542 23.6771Z"
        fill="url(#paint0_linear_1512_34)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1512_34"
          x1="7.00305"
          y1="25.0049"
          x2="41.9962"
          y2="25.0049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006AE1" />
          <stop offset="1" stopColor="#8457D9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default StarRecognitionIllustration;

import { Theme, useBuildStyleWithTheme } from '@cvent/carina/components/ThemeProvider';
import { CSSObject } from '@emotion/react';

const buildStyle = ({ font }: Theme): OverviewStyles => {
  return {
    container: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
      padding: '80px 30px'
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    taglineText: {
      fontSize: font.base.size.l,
      margin: '0 0 1.25rem',
      fontWeight: font.base.weight.light,
      textAlign: 'center'
    },
    actions: {
      display: 'flex',
      button: {
        marginLeft: '1rem',
        padding: '0.5rem 1rem'
      }
    },
    marketingContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: '20px'
    },
    marketingCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: '20%',
      padding: '10px'
    }
  };
};

type OverviewStyles = Record<
  'container' | 'header' | 'taglineText' | 'actions' | 'marketingContainer' | 'marketingCard',
  CSSObject
>;

/**
 * Creates the styling for the Overview component
 *
 * @returns Record containing CSSObjects for the parts in the Overview components
 */
export const useStyle = (): OverviewStyles => {
  const builtStyle = useBuildStyleWithTheme(buildStyle, {});
  return builtStyle;
};

import React from 'react';
import { useTranslate } from 'nucleus-text';
import OverviewPageIllustration from '@images/OverviewPageIllustration';
import { Button } from '@cvent/carina/components/Button';
import Link from 'next/link';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { deleteAccountCookies, deleteOktaSession } from '@utils';
import { useQuery } from '@apollo/client';
import { GetOktaSessionUrlDocument } from '@cvent/developer-portal-graphql/operations';
import LockIllustration from '@images/LockIllustration';
import PresentationProjectionScreenIllustration from '@images/PresentationProjectorScreenIllustration';
import StarRecognitionIllustration from '@images/StarRecognitionIllustration';
import TelecommunicatorIllustration from '@images/TelecommunicatorIllustration';
import { useStyle } from './OverviewStyles';

/**
 * Renders the Home/Overview page with links to login and to api reference page
 *
 * @returns Rendered Overview page
 */
function Overview(): JSX.Element {
  const { translate, resolveInnerHtmlTranslation } = useTranslate();
  const styles = useStyle();
  const _deleteSessionUrl = useQuery(GetOktaSessionUrlDocument).data?.getOktaSessionUrl;

  return (
    <div {...injectTestId('overview.container')} css={styles.container}>
      <OverviewPageIllustration testId="overview.illustration" />
      <div {...injectTestId('overview.header')} css={styles.header}>
        <h1 {...injectTestId('overview.header.title')}>{translate('buildWithUs')}</h1>

        <p {...injectTestId('overview.header.tagline')} css={styles.taglineText}>
          {translate('connectToOptimize')}
        </p>
      </div>

      <div {...injectTestId('overview.actions')} css={styles.actions}>
        <Link href="/login" legacyBehavior>
          <Button
            onClick={async () => {
              await deleteOktaSession(_deleteSessionUrl);
              await deleteAccountCookies();
            }}
            testID="overview.action.signIn"
            appearance="filled"
            text={translate('buttons.login')}
          />
        </Link>
        <Link href="/documentation" legacyBehavior>
          <Button
            appearance="ghost"
            testID="overview.action.documentation"
            text={translate('buttons.viewApiReference')}
          />
        </Link>
        <Link href="/docs" legacyBehavior>
          <Button appearance="ghost" testID="overview.action.docs" text={translate('buttons.viewDeveloperDocs')} />
        </Link>
      </div>
      <div css={styles.marketingContainer}>
        <div css={styles.marketingCard}>
          <PresentationProjectionScreenIllustration testId="overview.marketing.presentation" />
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={resolveInnerHtmlTranslation('marketing.partnership')} />
        </div>
        <div css={styles.marketingCard}>
          <StarRecognitionIllustration testId="overview.marketing.star" />
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={resolveInnerHtmlTranslation('marketing.trust')} />
        </div>
        <div css={styles.marketingCard}>
          <TelecommunicatorIllustration testId="overview.marketing.telecommunicator" />
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={resolveInnerHtmlTranslation('marketing.eventCount')} />
        </div>
        <div css={styles.marketingCard}>
          <LockIllustration testId="overview.marketing.lock" />
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={resolveInnerHtmlTranslation('marketing.standards')} />
        </div>
      </div>
    </div>
  );
}

export default Overview;
